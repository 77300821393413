export default [

    {
        name: "Panoramic Image Stitching",
        description: "Implemented an algorithm to stitch two images taken by a rotating camera.",
        language: "MATLAB",
        link: "https://github.com/takavor/Image-Stitching",
        github: true
    },
    {
        name: "Pendulum Simulator",
        description: "A simulation of swinging pendulums in a dynamic environment.",
        language: "JavaScript",
        link: "https://github.com/takavor/N-Pendulum-Simulator",
        github: true
    }
]