export default [
    {
        name: "Personal Website",
        description: "Created my own portfolio website.",
        language: "JavaScript | React | Node.js | HTML | CSS",
        link: "http://sevag.io"
    },
    {
        name: "Printzone Promo Website",
        description: "Created a website for my mother's printing business.",
        language: "JavaScript | React | Node.js | HTML | CSS",
        link: "https://printzonepromo.com/"
    }

]