export default [

    {
        name: "Simple Shell",
        description: "Implemented a simple command-line shell (based on Bash).",
        language: "C",
        link: "https://github.com/takavor/Simple-Shell",
        github: true
    },
    {
        name: "Video Library System",
        description: "Designed a video library system using software design patterns and principles.",
        language: "Java",
        link: "https://github.com/takavor/Video-Library-System",
        github: true
    }

]