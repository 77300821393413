import React from 'react'
import ReactDOM from 'react-dom'
import Project from "./Project"
import Skill from "./Skill"
import skillsData from './skillsData'

import machineLearningProjectsData from './machineLearningProjectsData'
import computerVisionProjectsData from './computerVisionProjectsData'
import websitesProjectsData from './websitesProjectsData'
import softwareSystemsProjectsData from './softwareSystemsProjectsData'
import gamesProjectsData from './gamesProjectsData'

export default function App() {

  const machineLearningProjects = machineLearningProjectsData.map(project => {
    return <Project name={project.name} description={project.description} link={project.link} language={project.language} github={project.github} />
  })

  const computerVisionProjects = computerVisionProjectsData.map(project => {
    return <Project name={project.name} description={project.description} link={project.link} language={project.language} github={project.github} />
  })

  const websitesProjects = websitesProjectsData.map(project => {
    return <Project name={project.name} description={project.description} link={project.link} language={project.language} github={project.github} />
  })

  const softwareSystemsProjects = softwareSystemsProjectsData.map(project => {
    return <Project name={project.name} description={project.description} link={project.link} language={project.language} github={project.github} />
  })

  const gamesProjects = gamesProjectsData.map(project => {
    return <Project name={project.name} description={project.description} link={project.link} language={project.language} github={project.github} />
  })

  const skills = skillsData.map(skill => {
    return <Skill skill={skill.skill} />
  })

  return (

    <div className="webpage">

      <header>
        <a className="home-button" href="">Sevag Baghdassarian</a>
        <div className="header-social-logos">
          <a href="https://github.com/takavor" target="_blank"><i class="fab fa-github fab-header"></i></a>
          <a href="https://www.linkedin.com/in/sevagba/" target="_blank"><i class="fab fa-linkedin fab-header"></i></a>
        </div>
        <nav class="nav-bar">
          <ul className="nav-links">
            <li className="navbar-item"><a className="navbar-link with-animated-underline" href="#resume">Résumé</a></li>
            <li className="navbar-item"><a className="navbar-link with-animated-underline" href="#projects">Projects</a></li>
            <li className="navbar-item"><a className="navbar-link with-animated-underline" href="#skills">Skills</a></li>
          </ul>
        </nav>


        <a className="contact" href="#contact"><button className="contact-button" id="contact-button">Contact</button></a>

        {/* dark mode checkbox */}
        <div className="darkmode-container">
          <input type="checkbox" className="checkbox" id="checkbox" class="checkbox"></input>

          <label for="checkbox" className="check-label">
            <i class="fas fa-sun"></i>
            <i class="fas fa-moon"></i>
            <div class="ball"></div>
          </label>
        </div>

      </header>


      <div className="about-section">

        <div className="about-me-div">
          <h1 className="title">Hi, I'm <span className="name-letter name-letter-red">S</span><span className="name-letter name-letter-red">e</span><span className="name-letter name-letter-blue">v</span><span className="name-letter name-letter-orange">a</span><span className="name-letter name-letter-orange">g</span>.</h1>
          {/* <h2 className="title-description">an up-and-coming Machine Learning engineer.</h2> */}
          <p className="about-me-text">I am a master's student of Computer Science at McGill University with an interest in Artificial Intelligence, Machine Learning, and Data Science. I love teaching myself all kinds of things, solving problems, and exploring new challenges. My hobbies include playing music and gaming. <strong className="scroll-text">Scroll your screen up and down to shake the pendulums, and click on + to add more!</strong></p>
        </div>

        <div className="pendulum-sim">
          <div id="wrapdiv">

            <canvas id="canvas" className="canvas" draggable="true"></canvas>
          </div>
          {/* <p className="pendulum-description">Scroll your screen up and down to shake the pendulums!</p> */}
          <button className="minus pendulum-button" id="minus">-</button>
          <button className="plus pendulum-button" id="plus">+</button>

        </div>

      </div>

      <div className="section-header" id="resume">
        <h2 className="section-title with-animated-underline-larger with-animated-underline">RÉSUMÉ</h2>
        <div className="cv-container">
          Click <a className="with-animated-underline cv-download-button" href={require("./assets/Sevag_Baghdassarian_CV.pdf")} download="Sevag_Baghdassarian_CV">here</a> to download my résumé.
        </div>

      </div>


      <div className="section-header" id="projects">
        <h2 className="section-title with-animated-underline-larger with-animated-underline">PROJECTS</h2>

        <div className="projects-section-container">
          <h2 className="with-animated-underline subsection">Machine Learning</h2>

          <div className="projects-section">
            {machineLearningProjects}
          </div>
        </div>

        <div>
          <h2 className="with-animated-underline subsection">Computer Vision & Animation</h2>

          <div className="projects-section">
            {computerVisionProjects}
          </div>
        </div>

        <div>
          <h2 className="with-animated-underline subsection">Websites</h2>

          <div className="projects-section">
            {websitesProjects}
          </div>
        </div>

        <div>
          <h2 className="with-animated-underline subsection">Software & Systems</h2>

          <div className="projects-section">
            {softwareSystemsProjects}
          </div>
        </div>

        {/* <div>
          <h2 className="section-title with-animated-underline-larger with-animated-underline subsection">Games</h2>

          <div className="projects-section">
            {gamesProjects}
          </div>
        </div> */}


      </div>

      <div className="section-header" id="skills">
        <h2 className="section-title with-animated-underline-larger with-animated-underline">SKILLS</h2>
        <div className="skills-section">
          {skills}
        </div>
      </div>

      <div className="section-header" id="contact">
        <h2 className="section-title with-animated-underline-larger with-animated-underline">CONTACT</h2>

        <div className="contact-logos">
          <a href="https://github.com/takavor" target="_blank"><i class="fab fa-github fab-header"></i></a>
          <a href="https://www.linkedin.com/in/sevagba/" target="_blank"><i class="fab fa-linkedin fab-header"></i></a>
        </div>

        <div className="contact-section">

          <form id="my-form" action="https://formspree.io/f/xrgjkewo" method="POST">

            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="name" name="name"></input>
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" name="email"></input>
            </div>

            <div class="form-group">
              <label for="message">Message</label>
              <textarea id="message" name="message" cols="50" rows="10"></textarea>
            </div>

            <div class="form-group send-button-group">
              <button class="send-button" className="send-button" type="submit">Send</button>
            </div>



          </form>

        </div>



      </div>

    </div>

  );
}

