export default [
    {
        skill: "Python"
    },
    {
        skill: "R"
    },
    {
        skill: "Pandas"
    },
    {
        skill: "Scikit-Learn"
    },
    {
        skill: "PyTorch"
    },
    {
        skill: "Keras"
    },
    {
        skill: "TensorFlow"
    },
    {
        skill: "OpenCV"
    },
    {
        skill: "MATLAB"
    },
    {
        skill: "Java"
    } 
    ,{
        skill: "JavaScript"
    }, 
    ,
    {
        skill: "C"
    },
    {
        skill: "HTML"
    }, 
    {
        skill: "CSS"
    }, 
    {
        skill: "React"
    },
    {
        skill: "Node.js"
    }, 
    {
        skill: "OCaml"
    },
    {
        skill: "Linux"
    },
    {
        skill: "Assembly"
    },
    {
        skill: "Bash"
    },
    {
        skill: "LaTeX"
    },
    {
        skill: "Redmine"
    },
    {
        skill: "Wiki"
    }
]