import React from 'react'

export default function Project(props) {
    console.log(props)
    return (
        <div className="project-container">

            <a href={"" + props.link + ""} target="_blank" className="project-name with-animated-underline">{props.name}</a>
            <p>{props.description}</p>

            <div className="logo-container">
                {
                    props.github ?
                        <a href={props.link} target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github"></i> {/* GitHub logo */}
                        </a>
                        :
                        <a href={props.link} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-link"></i> {/* Link logo */}
                        </a>
                }
            </div>

            <div className="language-container">
                {props.language}
            </div>


        </div>
    )
}